.mouse-drag {
    width: 8vw;
    height: 8vw;
    min-width: 80px;
    min-height: 80px;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 50%;
    z-index: 10;
    transform-origin: center;
    user-select: none;
    pointer-events: none;
    opacity: 1;
    transition: opacity 0.2s;
    transform: translate3d(0, -100vh, 0);
}

.mouse-drag span {
    display: block;
    color: white;
    font-size: max(1.25vw, 16px);
    text-align: center;
    transition: opacity 0.2s;
}

.mouse-drag img {
    width: 2vw;
    min-width: 18px;
    height: auto;
    transition: transform 0.5s;
}

.mouse-drag:after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    transition: width 0.5s, height 0.5s;
    transform-origin: center;
    border-radius: 50%;
    border: 1px solid #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.mouse-drag.dragging:after {
    width: 65%;
    height: 65%;
}

.mouse-drag.hidden {
    opacity: 0;
}

.mouse-drag.dragging .top {
    transform: translateY(-100%);
}

.mouse-drag.dragging .bottom {
    transform: translateY(100%);
}

.mouse-drag.bingo .drag {
    opacity: 0;
}

.mouse-drag.bingo .click {
    opacity: 1;
}

.mouse-drag .drag {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.mouse-drag .drag span {
    margin: 0.5vw 0;
}

.mouse-drag .click {
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 1.2;
    width: 100%;
}

@media screen and (min-width: 1280px) {
    .mouse-drag.dragging:after {
        width: 50%;
        height: 50%;
    }

    .mouse-drag.dragging .top {
        transform: translateY(-50%);
    }

    .mouse-drag.dragging .bottom {
        transform: translateY(50%);
    }
}
