@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@300&display=swap");
@import url("https://use.typekit.net/ixl8gsz.css");

html,
body,
.app,
canvas {
    width: 100%;
    height: 100%;
    user-select: none;
    cursor: none;
}

body {
    background-color: #171717;
    overflow: hidden;
}

.app {
    touch-action: none;
}

span {
    font-family: "neue-haas-unica", "Noto Sans", sans-serif;
}

.cancel-bingo-camera {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
}

.processing-ui {
    position: fixed;
    z-index: 10;
    left: 0;
    width: 100%;
    bottom: 20px;
}

@media screen and (min-width: 1024px) {
    .processing-ui {
        bottom: 65px;
    }
}
