.processing-ui,
.processing-ui .ui {
    display: flex;
    align-items: center;
    justify-content: center;
}

.processing-ui {
    opacity: 0;
    transform: translateY(100%);
    transition: opacity 0.2s 1.9s, transform 0.5s 1.9s;
}

.processing-ui.visible {
    opacity: 1;
    transform: translateY(0%);
}

.processing-ui span {
    display: block;
    margin: 0 10px;
    color: #fff;
    font-size: min(max(1.3vw, 14px), 25px);
    line-height: 1;
}

.ui {
    min-width: 200px;
}

.circle {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 1px solid #fff;
    background-color: transparent;
    transition: background-color 0.5s;
}

.circle.now {
    background-color: #fff;
}

.line {
    width: 76px;
    height: 1px;
    background-color: #fff;
    display: block;
}

@media screen and (min-width: 768px) {
    .processing-ui span {
        margin: 0 15px;
    }

    .ui {
        width: 20vw;
        max-width: 375px;
    }

    .circle {
        width: 1.35vw;
        height: 1.35vw;
        max-width: 26px;
        max-height: 26px;
    }

    .line {
        width: 8.5vw;
        max-width: 161.5px;
    }
}
