.words {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    width: fit-content;
    opacity: 0;
    transition: opacity 0.8s;
    pointer-events: none;
}

.words.visible {
    opacity: 1;
}

.words-first {
    transform: translate(2%, 0%) rotate(26deg);
}

.words-first img {
    width: 62vw;
}

.words-second {
    transform: translate(4%, 15%) rotate(-30deg);
}

.words-second img {
    width: 80vw;
}

.words-third {
    transform: translate(0%, 0%);
}

.words-third img {
    width: 80vw;
}

@media screen and (min-width: 768px) {
    .words-first {
        transform: translate(7%, 2%) rotate(24.5deg);
    }

    .words-second {
        transform: translate(8%, -13%) rotate(-30deg);
    }

    .words-second img {
        width: 45vw;
    }

    .words-third img {
        width: 50vw;
    }
}

@media screen and (min-width: 1024px) {
    .words-first {
        transform: translate(12%, 6%) rotate(24.5deg);
    }

    .words-first img {
        width: 42vh;
    }

    .words-second {
        transform: translate(4%, -5%) rotate(-30deg);
    }

    .words-third {
        transform: translate(0, -15%);
    }

    .words-third img {
        width: 60vh;
    }
}

@media screen and (min-width: 1280px) {
    .words-first {
        transform: translate(15%, 4%) rotate(24.5deg);
    }

    .words-first img {
        width: 44vh;
    }

    .words-second {
        transform: translate(6%, -8%) rotate(-30deg);
    }

    .words-second img {
        width: 65vh;
    }
}
