header {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 10;
    padding: 20px;
    width: calc(100% - 40px);

    min-height: 55px;
    max-height: 110px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

header img {
    width: auto;
    height: 5.7vw;
    min-height: 55px;
    user-select: none;
}

.year {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    flex-wrap: wrap;
    width: 65px;
}

.year-number span {
    opacity: 0;
    transition: opacity 0.2s var(--delay);
    color: white;
    font-size: 14px;
    font-weight: 300;
}

.visible .year-number span {
    opacity: 1;
}

.year-arrow {
    width: 25px;
    height: auto;
    margin-top: 10px;
}

.year-arrow path,
.year-arrow polyline {
    fill: none;
    stroke: #fff;
    stroke-miterlimit: 10;
    stroke-dashoffset: 50;
    stroke-dasharray: 50;
    transition: stroke-dashoffset 0.5s var(--delay);
}

.visible .year-arrow path,
.visible .year-arrow polyline {
    stroke-dashoffset: 0;
}

.year-2021 {
    margin-right: 5px;
}

.year-2022 {
    writing-mode: vertical-rl;
    margin: 5px -5px 0 0;
}

@media screen and (min-width: 1024px) {
    header {
        padding: 40px 55px 0px;
        width: calc(100% - 110px);
    }

    .year {
        width: 6vw;
        max-width: 100px;
    }

    .year-number span {
        font-size: min(1.3vw, 25px);
    }

    .year-arrow {
        width: 1.8vw;
        max-width: 35px;
        margin-top: 0.8vw;
    }

    .year-2022 {
        margin-right: -0.5vw;
    }
}
