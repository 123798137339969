@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@300&display=swap);
@import url(https://use.typekit.net/ixl8gsz.css);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.processing-ui,
.processing-ui .ui {
    display: flex;
    align-items: center;
    justify-content: center;
}

.processing-ui {
    opacity: 0;
    transform: translateY(100%);
    transition: opacity 0.2s 1.9s, transform 0.5s 1.9s;
}

.processing-ui.visible {
    opacity: 1;
    transform: translateY(0%);
}

.processing-ui span {
    display: block;
    margin: 0 10px;
    color: #fff;
    font-size: min(max(1.3vw, 14px), 25px);
    line-height: 1;
}

.ui {
    min-width: 200px;
}

.circle {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 1px solid #fff;
    background-color: transparent;
    transition: background-color 0.5s;
}

.circle.now {
    background-color: #fff;
}

.line {
    width: 76px;
    height: 1px;
    background-color: #fff;
    display: block;
}

@media screen and (min-width: 768px) {
    .processing-ui span {
        margin: 0 15px;
    }

    .ui {
        width: 20vw;
        max-width: 375px;
    }

    .circle {
        width: 1.35vw;
        height: 1.35vw;
        max-width: 26px;
        max-height: 26px;
    }

    .line {
        width: 8.5vw;
        max-width: 161.5px;
    }
}

.mouse-drag {
    width: 8vw;
    height: 8vw;
    min-width: 80px;
    min-height: 80px;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 50%;
    z-index: 10;
    transform-origin: center;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    pointer-events: none;
    opacity: 1;
    transition: opacity 0.2s;
    transform: translate3d(0, -100vh, 0);
}

.mouse-drag span {
    display: block;
    color: white;
    font-size: max(1.25vw, 16px);
    text-align: center;
    transition: opacity 0.2s;
}

.mouse-drag img {
    width: 2vw;
    min-width: 18px;
    height: auto;
    transition: transform 0.5s;
}

.mouse-drag:after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    transition: width 0.5s, height 0.5s;
    transform-origin: center;
    border-radius: 50%;
    border: 1px solid #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.mouse-drag.dragging:after {
    width: 65%;
    height: 65%;
}

.mouse-drag.hidden {
    opacity: 0;
}

.mouse-drag.dragging .top {
    transform: translateY(-100%);
}

.mouse-drag.dragging .bottom {
    transform: translateY(100%);
}

.mouse-drag.bingo .drag {
    opacity: 0;
}

.mouse-drag.bingo .click {
    opacity: 1;
}

.mouse-drag .drag {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.mouse-drag .drag span {
    margin: 0.5vw 0;
}

.mouse-drag .click {
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 1.2;
    width: 100%;
}

@media screen and (min-width: 1280px) {
    .mouse-drag.dragging:after {
        width: 50%;
        height: 50%;
    }

    .mouse-drag.dragging .top {
        transform: translateY(-50%);
    }

    .mouse-drag.dragging .bottom {
        transform: translateY(50%);
    }
}

header {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 10;
    padding: 20px;
    width: calc(100% - 40px);

    min-height: 55px;
    max-height: 110px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

header img {
    width: auto;
    height: 5.7vw;
    min-height: 55px;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.year {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    flex-wrap: wrap;
    width: 65px;
}

.year-number span {
    opacity: 0;
    transition: opacity 0.2s var(--delay);
    color: white;
    font-size: 14px;
    font-weight: 300;
}

.visible .year-number span {
    opacity: 1;
}

.year-arrow {
    width: 25px;
    height: auto;
    margin-top: 10px;
}

.year-arrow path,
.year-arrow polyline {
    fill: none;
    stroke: #fff;
    stroke-miterlimit: 10;
    stroke-dashoffset: 50;
    stroke-dasharray: 50;
    transition: stroke-dashoffset 0.5s var(--delay);
}

.visible .year-arrow path,
.visible .year-arrow polyline {
    stroke-dashoffset: 0;
}

.year-2021 {
    margin-right: 5px;
}

.year-2022 {
    -ms-writing-mode: tb-rl;
        writing-mode: vertical-rl;
    margin: 5px -5px 0 0;
}

@media screen and (min-width: 1024px) {
    header {
        padding: 40px 55px 0px;
        width: calc(100% - 110px);
    }

    .year {
        width: 6vw;
        max-width: 100px;
    }

    .year-number span {
        font-size: min(1.3vw, 25px);
    }

    .year-arrow {
        width: 1.8vw;
        max-width: 35px;
        margin-top: 0.8vw;
    }

    .year-2022 {
        margin-right: -0.5vw;
    }
}

.words {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    opacity: 0;
    transition: opacity 0.8s;
    pointer-events: none;
}

.words.visible {
    opacity: 1;
}

.words-first {
    transform: translate(2%, 0%) rotate(26deg);
}

.words-first img {
    width: 62vw;
}

.words-second {
    transform: translate(4%, 15%) rotate(-30deg);
}

.words-second img {
    width: 80vw;
}

.words-third {
    transform: translate(0%, 0%);
}

.words-third img {
    width: 80vw;
}

@media screen and (min-width: 768px) {
    .words-first {
        transform: translate(7%, 2%) rotate(24.5deg);
    }

    .words-second {
        transform: translate(8%, -13%) rotate(-30deg);
    }

    .words-second img {
        width: 45vw;
    }

    .words-third img {
        width: 50vw;
    }
}

@media screen and (min-width: 1024px) {
    .words-first {
        transform: translate(12%, 6%) rotate(24.5deg);
    }

    .words-first img {
        width: 42vh;
    }

    .words-second {
        transform: translate(4%, -5%) rotate(-30deg);
    }

    .words-third {
        transform: translate(0, -15%);
    }

    .words-third img {
        width: 60vh;
    }
}

@media screen and (min-width: 1280px) {
    .words-first {
        transform: translate(15%, 4%) rotate(24.5deg);
    }

    .words-first img {
        width: 44vh;
    }

    .words-second {
        transform: translate(6%, -8%) rotate(-30deg);
    }

    .words-second img {
        width: 65vh;
    }
}

html,
body,
.app,
canvas {
    width: 100%;
    height: 100%;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    cursor: none;
}

body {
    background-color: #171717;
    overflow: hidden;
}

.app {
    touch-action: none;
}

span {
    font-family: "neue-haas-unica", "Noto Sans", sans-serif;
}

.cancel-bingo-camera {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
}

.processing-ui {
    position: fixed;
    z-index: 10;
    left: 0;
    width: 100%;
    bottom: 20px;
}

@media screen and (min-width: 1024px) {
    .processing-ui {
        bottom: 65px;
    }
}

